import { BankTransactionAnalysisStateConclusion, BankTransactionAnalysisStateConclusionTypeEnum, InvoiceAnalysisConclusion, NormalizedBankTransaction } from '../../api/generated';

export function getTitleForBankTransactionAnalysisConclusion(bankTransaction: NormalizedBankTransaction, conclusion: BankTransactionAnalysisStateConclusion | InvoiceAnalysisConclusion) {
  const noun = 'transaction';
  switch (conclusion.type) {
    case BankTransactionAnalysisStateConclusionTypeEnum.DocumentIsOutlier:
      return `This ${noun} is a statistical outlier`;
    case BankTransactionAnalysisStateConclusionTypeEnum.LineItemIsOutlier:
      return `Line item #${(conclusion.data as any).lineItem.ordinal + 1} is a statistical outlier`;
    default:
      return `Unknown`;
  }
}
