import { Badge, Group, Text } from '@mantine/core';
import { BankTransactionAnalysisStateConclusion, NormalizedBankTransaction } from '../../api/generated';

import { formatDateTimeDynamic } from '../../lib/formatters';
import { IconFlagExclamation, IconInfoCircle } from '@tabler/icons-react';
// import { ContactDetailAnalysisConclusionModal } from './InvoiceDetailAnalysisConclusionModal';
import { useDisclosure } from '@mantine/hooks';

import classes from './BankTransactionDetailAnalysisConclusionListItem.module.css';
import { ConclusionSeverity } from '../../lib/conclusionSeverity';
import { getTitleForBankTransactionAnalysisConclusion } from '../../lib/invoiceAnalysis/getTitleForBankTransactionAnalysisConclusion';
import { getSeverityForBankTransactionAnalysisConclusionType } from '../../lib/invoiceAnalysis/getSeverityForBankTransactionAnalysisConclusionType';
import { getDescriptionForBankTransactionAnalysisConclusion } from '../../lib/invoiceAnalysis/getDescriptionForInvoiceAnalysisConclusion';
import { BankTransactionDetailAnalysisConclusionModal } from './BankTransactionDetailAnalysisConclusionModal';

const getBadgePropsForConclusionSeverity = (severity: ConclusionSeverity) => {
  switch (severity) {
    case 'critical':
      return { variant: 'filled', color: 'red.7', children: ['Critical'] };
    case 'important':
      return { color: 'orange.5', children: ['Important'] };
    case 'advisory':
      return { color: 'blue', children: ['Advisory'] };
  }
}

const getIconForSeverity = (severity: ConclusionSeverity) => {
  switch (severity) {
    case 'advisory':
      return <IconInfoCircle color='var(--mantine-color-blue-6)' />;
    case 'important':
      return <IconFlagExclamation color='var(--mantine-color-orange-5)' />;
    case 'critical':
      return <IconFlagExclamation color='var(--mantine-color-red-7)' />;

  }
}

export function BankTransactionDetailAnalysisConclusionListItem({ bankTransaction, conclusion}: { bankTransaction: NormalizedBankTransaction, conclusion: BankTransactionAnalysisStateConclusion }) {

  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure(false);

  const conclusionTypeLabel = getTitleForBankTransactionAnalysisConclusion(bankTransaction, conclusion);
  const conclusionSeverity = getSeverityForBankTransactionAnalysisConclusionType(conclusion.type);
  
  return (
    <>
      <BankTransactionDetailAnalysisConclusionModal
        opened={modalOpened} 
        onClose={closeModal} 
        bankTransaction={bankTransaction}
        conclusion={conclusion}
      />
      <div className={classes.root} onClick={openModal}>
        <Group wrap="nowrap">
          {getIconForSeverity(conclusionSeverity)}
          <Badge mb={1} w="80px" size="sm" {...getBadgePropsForConclusionSeverity(conclusionSeverity)} />
          <Text className={classes.conclusionTypeLabel}>{conclusionTypeLabel}</Text>
        </Group>
        <div className={classes.conclusionBody}>
          <Text c="dimmed" lineClamp={1}>
            {getDescriptionForBankTransactionAnalysisConclusion(bankTransaction, conclusion)[0]}
          </Text>
        </div>
        <div className={classes.conclusionTimestamp}>
          <Text fz="xs" c="dimmed">First detected</Text>
          <Text fz="sm">{formatDateTimeDynamic(conclusion.created)}</Text>
        </div>
      </div>
    </>
  );

}