import { InvoiceAnalysisConclusionTypeEnum, InvoiceAnalysisStateConclusionTypeEnum } from '../../api/generated';
import { ConclusionSeverity } from '../conclusionSeverity';

export function getSeverityForInvoiceAnalysisConclusionType(conclusionType: InvoiceAnalysisStateConclusionTypeEnum | InvoiceAnalysisConclusionTypeEnum): ConclusionSeverity {
  switch (conclusionType) {
    case InvoiceAnalysisStateConclusionTypeEnum.BankDetailsChanged:
      return 'critical';
    case InvoiceAnalysisStateConclusionTypeEnum.PossibleDuplicate:
      return 'important';
    default: 
      return 'advisory';
  }
}
