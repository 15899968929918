import { Text, Group, Paper, Divider, Center, Stack, rem } from '@mantine/core';
import { IconZzz } from '@tabler/icons-react';
import { BankTransaction, BankTransactionAnalysisState, BankTransactionAnalysisStateConclusionTypeEnum } from '../../api/generated';

import classes from './BankTransactionDetailAnalysisConclusionList.module.css';
import { BankTransactionDetailAnalysisConclusionListItem } from './BankTransactionDetailAnalysisConclusionListItem';

export function BankTransactionDetailAnalysisConclusionList({ bankTransaction, bankTransactionAnalysisState }: { bankTransaction: BankTransaction, bankTransactionAnalysisState: BankTransactionAnalysisState | undefined }) {

  const data = (bankTransactionAnalysisState?.conclusions || []).filter(c => ![BankTransactionAnalysisStateConclusionTypeEnum.AttachmentDataExtracted].includes(c.type));

  return (
    <Paper id="alerts" shadow="xs" radius="md" p="lg">
      <Group justify="space-between">
        <Text fz="lg" fw={500}>Alerts & Advisories</Text>
      </Group>
      <Divider mt="xs" />
      {data.length ? (
        <div className={classes.root}>          
            {data.map((conclusion) => (
              <>
                {/*
                could group by line item if wanted...
                 <div className={classes.subheadingItem}>                  
                    <Group align="center">
                      <Text fw={500}>Re:</Text>
                      <Text>{companyData.companyName}</Text>
                      <Text c="dimmed" fz="sm">{companyData.companyNumber}</Text>
                    </Group>
                </div> */}
                {/* {conclusions.map(conclusion => ( */}
                  <BankTransactionDetailAnalysisConclusionListItem bankTransaction={bankTransactionAnalysisState!.normalizedBankTransaction} conclusion={conclusion} />
                {/* ))} */}
              </>
            ))}
        </div>
      ) : (
        <Stack ta="center" h={rem(200)} justify="center">
          <Center mt="md">
            <IconZzz color="var(--mantine-color-gray-6)" style={{ height: rem(48), width: rem(48) }} stroke={1}/>            
          </Center>
          <Text c="dimmed" fz="sm" fw={700}>Nothing to report</Text>          
          <Text c="dimmed" fz="sm">We've not found anything about this contact to bring to your attention.<br/>If we detect changes that might be a cause for concern, we'll notify you straight away.</Text>
          <Text c="dimmed" fz="sm"></Text>
        </Stack>
      )}
    </Paper>
  );

}