import { BankTransactionAnalysisStateConclusionTypeEnum } from '../../api/generated';
import { ConclusionSeverity } from '../conclusionSeverity';

export function getSeverityForBankTransactionAnalysisConclusionType(conclusionType: BankTransactionAnalysisStateConclusionTypeEnum): ConclusionSeverity {
  switch (conclusionType) {
    case BankTransactionAnalysisStateConclusionTypeEnum.BankDetailsChanged:
      return 'critical';
    case BankTransactionAnalysisStateConclusionTypeEnum.PossibleDuplicate:
      return 'important';
    default: 
      return 'advisory';
  }
}
