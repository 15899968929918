import { Link } from 'react-router-dom';
import { BankTransactionAnalysisStateConclusion, BankTransactionAnalysisStateConclusionTypeEnum, InvoiceAnalysisStateConclusion, InvoiceAnalysisStateConclusionTypeEnum, NormalizedBankTransaction, NormalizedInvoice } from '../../api/generated';
import { formatConjunctiveList, formatDateShort, formatNounForNormalizedDocument } from '../formatters';
import pluralize from 'pluralize';
import { Anchor } from '@mantine/core';

function getOutlierDescriptionIntro(fields: string[]) {
  return `The ${formatConjunctiveList(fields)} ${pluralize('field', fields.length)} ${pluralize('has', fields.length)} been identified as${fields.length === 1 ? ` a ` : ` `}${pluralize(`statistical outlier`, fields.length)}.`;
}

export function getDescriptionForInvoiceAnalysisConclusion(invoice: NormalizedInvoice, conclusion: InvoiceAnalysisStateConclusion) {
  const data = conclusion.data as any;
  const meta = conclusion.meta as any;

  switch (conclusion.type) {
    case InvoiceAnalysisStateConclusionTypeEnum.DocumentIsOutlier:
    case InvoiceAnalysisStateConclusionTypeEnum.LineItemIsOutlier:
      return [
        getOutlierDescriptionIntro(meta.outliers.map((o: any) => o.field))
      ];
    case InvoiceAnalysisStateConclusionTypeEnum.PossibleDuplicate:
      const otherDocumentHrefRoot = (conclusion.data as any).duplicate.item.entity === 'NormalizedInvoice' ? 'invoices' : 'bank-transactions';
      const otherDocumentDate = formatDateShort((conclusion.data as any).duplicate.item.date);
      const otherDocumentRef = (conclusion.data as any).duplicate.item.reference || (conclusion.data as any).duplicate.item.invoiceNumber;
      const otherDocumentNoun = formatNounForNormalizedDocument((conclusion.data as any).duplicate.item);
      const otherDocumentDescriptor = `another ${otherDocumentNoun} dated ${otherDocumentDate}${otherDocumentRef ? `, ref ${otherDocumentRef}` : ''}`;
      return [
        `This ${formatNounForNormalizedDocument(invoice, false)} is a possible duplicate of ${otherDocumentDescriptor}.`,
        <Anchor inherit component={Link} to={`../${otherDocumentHrefRoot}/${(conclusion.data as any).duplicate.item.extId}`}>View the other {otherDocumentNoun}</Anchor>
      ];
    case InvoiceAnalysisStateConclusionTypeEnum.BankDetailsChanged:

    const thisAccountName = (conclusion.data as any).detectedBankDetails.paymentBankAccountName;
    const thisAccountNum = (conclusion.data as any).detectedBankDetails.paymentBankAccountNumber;
    const thisAccountSort = (conclusion.data as any).detectedBankDetails.paymentBankAccountSortCode;

    const otherAccountName = (conclusion.data as any).latestOtherBankDetails.paymentBankAccountName;
    const otherAccountNum = (conclusion.data as any).latestOtherBankDetails.paymentBankAccountNumber;
    const otherAccountSort = (conclusion.data as any).latestOtherBankDetails.paymentBankAccountSortCode;

    
      return [
        `The bank details detected for this ${formatNounForNormalizedDocument(invoice, false)} are:`,
        [
          thisAccountName ? <>Account Name: {thisAccountName} <br /></> : null,
          thisAccountNum ? <>Account Number: {thisAccountNum} <br /></> : null,
          thisAccountSort ? <>Sort Code: {thisAccountSort} <br /></> : null,
        ].filter(x => !!x),
        `The bank details observed previously for this contact were:`,
        [
          otherAccountName ? <>Account Name: {otherAccountName} <br /></> : null,
          otherAccountNum ? <>Account Number: {otherAccountNum} <br /></> : null,
          otherAccountSort ? <>Sort Code: {otherAccountSort} <br /></> : null,
        ].filter(x => !!x),
        'This may indicate a potential risk of fraud or unauthorized changes to payment information.'
      ];
    default: 
      return [
        `Unknown`
      ];
  }
}


export function getDescriptionForBankTransactionAnalysisConclusion(bankTransaction: NormalizedBankTransaction, conclusion: BankTransactionAnalysisStateConclusion) {
  const data = conclusion.data as any;
  const meta = conclusion.meta as any;
  switch (conclusion.type) {
    case BankTransactionAnalysisStateConclusionTypeEnum.DocumentIsOutlier:
    case BankTransactionAnalysisStateConclusionTypeEnum.LineItemIsOutlier:
      return [
        getOutlierDescriptionIntro(meta.outliers.map((o: any) => o.field))
      ];
    case BankTransactionAnalysisStateConclusionTypeEnum.PossibleDuplicate:
      const otherDocumentHrefRoot = (conclusion.data as any).duplicate.item.entity === 'NormalizedInvoice' ? 'invoices' : 'bank-transactions';
      const otherDocumentDate = formatDateShort((conclusion.data as any).duplicate.item.date);
      const otherDocumentRef = (conclusion.data as any).duplicate.item.reference || (conclusion.data as any).duplicate.item.invoiceNumber;
      const otherDocumentNoun = formatNounForNormalizedDocument((conclusion.data as any).duplicate.item);
      const otherDocumentDescriptor = `another ${otherDocumentNoun} dated ${otherDocumentDate}${otherDocumentRef ? `, ref ${otherDocumentRef}` : ''}`;
      return [
        `This transaction is a possible duplicate of ${otherDocumentDescriptor}.`,
        <Anchor inherit component={Link} to={`../${otherDocumentHrefRoot}/${(conclusion.data as any).duplicate.item.extId}`}>View the other {otherDocumentNoun}</Anchor>
      ];
    default:
      return [`Unknown`];
  }
}
