import { Divider, Space, Text } from '@mantine/core';
import { BankTransactionAnalysisStateConclusion, ContactAnalysisStateConclusion, InvoiceAnalysisStateConclusion, Message, NormalizedBankTransaction, NormalizedContact, NormalizedInvoice } from '../../../api/generated';
import { getDescriptionForContactAnalysisConclusion } from '../../../lib/contactAnalysis/getDescriptionForContactAnalysisConclusion';
import { getRecommendationForContactAnalysisConclusion } from '../../../lib/contactAnalysis/getRecommendationForContactAnalysisConclusion';
import { getAlertHeadingForContactAnalysisConclusion } from '../../../lib/contactAnalysis/getAlertHeadingForContactAnalysisConclusion';
import { getDescriptionForInvoiceAnalysisConclusion } from '../../../lib/invoiceAnalysis/getDescriptionForInvoiceAnalysisConclusion';
import { getRecommendationForInvoiceAnalysisConclusion } from '../../../lib/invoiceAnalysis/getRecommendationForInvoiceAnalysisConclusion';
import { getAlertHeadingForInvoiceAnalysisConclusion } from '../../../lib/invoiceAnalysis/getAlertHeadingForInvoiceAnalysisConclusion';
import React from 'react';

export function NewCriticalAlertTemplate({ message }: { message: Message }) {
  const data: {
    conclusion: ContactAnalysisStateConclusion | InvoiceAnalysisStateConclusion | BankTransactionAnalysisStateConclusion;
    xeroContactId?: string;
    xeroInvoiceId?: string;
    xeroBankTransactionId?: string;
    normalizedContact?: NormalizedContact;  
    normalizedInvoice?: NormalizedInvoice;
    normalizedBankTransaction?: NormalizedBankTransaction;
    companyMatchConclusion?: ContactAnalysisStateConclusion;
  } = message.contentData as any;

  
  let heading: React.ReactNode;
  let description: React.ReactNode[];
  let recommendation: React.ReactNode[];

  if (data.normalizedContact) {
    heading = getAlertHeadingForContactAnalysisConclusion(data.conclusion as ContactAnalysisStateConclusion, data.xeroContactId!, data.normalizedContact?.contactName!, data.companyMatchConclusion?.data.seekCompanyResult?.companyName);
    description = getDescriptionForContactAnalysisConclusion(data.conclusion as ContactAnalysisStateConclusion, data.companyMatchConclusion?.data.seekCompanyResult?.companyName);
    recommendation = getRecommendationForContactAnalysisConclusion(data.conclusion as ContactAnalysisStateConclusion, data.companyMatchConclusion?.data.seekCompanyResult?.companyName);
  }

  if (data.normalizedInvoice) {
    heading = getAlertHeadingForInvoiceAnalysisConclusion(data.normalizedInvoice, data.conclusion as InvoiceAnalysisStateConclusion);
    description = getDescriptionForInvoiceAnalysisConclusion(data.normalizedInvoice, data.conclusion as InvoiceAnalysisStateConclusion);
    recommendation = getRecommendationForInvoiceAnalysisConclusion(data.normalizedInvoice, data.conclusion as InvoiceAnalysisStateConclusion);
  }
  
  
  
  

  return <>
    <Text fw={700}>{heading}</Text>
    <Space h="md" />
    <Text fw={500}>Findings</Text>      
    <Divider mb="sm" />
    {description!.map((text, i) => <Text key={i}>{text}</Text>)}

    <Space h="md" />
    <Text fw={500}>Recommendation</Text>
    <Divider mb="sm" />
    {recommendation!.map((text, i) => <Text key={i}>{text}</Text>)}
    
  </>;
}