import dayjs from 'dayjs';
import { BankTransaction, ContactAnalysisCompanyNatureOfBusiness, ContactAnalysisDataCompanyStatusEnum, Invoice, InvoiceStatusEnum, InvoiceTypeEnum, NormalizedBankTransaction, NormalizedBankTransactionTypeEnum, NormalizedInvoice, NormalizedInvoiceTypeEnum, SeekCompanyResultAddress, SeekCompanyResultCompanyStatusEnum, SubscriptionPlanEnum, XeroUserOrganisationRoleEnum } from '../api/generated';
import { companiesHouseEnumerations } from './companiesHouseEnumerations';
import { capitalize, startCase } from 'lodash';
import { isSpend } from './invoiceAnalysis/helpers';
import validator from 'validator';

export function formatNumber(value: number) {
  return value.toLocaleString('en-GB');
}

export function formatDateShort(date: string) {
  return dayjs(date).format('D/M/YY');
}

export function formatDateMedium(date: string) {
  return dayjs(date).format('Do MMM YY');
}


export function formatDateLong(date: string) {
  return dayjs(date).format('Do MMMM YYYY');
}

export function formatDateTimeShort(date: string) {
  return dayjs(date).format('D/M/YY HH:mm');
}

export function formatDateTimeDynamic(date: string) {
  if (dayjs(date).isBefore(dayjs().startOf('day'))) return dayjs(date).format('D MMM');
  return dayjs(date).format('HH:mm');
}

export function formatDateTimeRelative(date: string) {
  return dayjs(date).fromNow();
}

export function formatDateForFileName(date: string) {
  return dayjs(date).format('DD-MM-YY');
}


export function formatCurrency(value: number, currencyCode?: string) {
  const validatedCurrencyCode = currencyCode && validator.isISO4217(currencyCode) ? currencyCode : undefined;  
  
  if (currencyCode && !validatedCurrencyCode) return formatCurrencyNoSymbol(value);

  return value.toLocaleString('en-GB', { style: 'currency',  currency: validatedCurrencyCode || 'GBP', maximumFractionDigits: 3 });
}

export function formatCurrencyNoSymbol(value: number) {
  return value.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function formatPercentage(value: number) {
  return `${(value * 100).toFixed()}%`;
}

export function formatRegisteredOfficeAddressShort(address: SeekCompanyResultAddress) {
  return [
    address.premises,
    address.addressLine1,
    address.locality || address.region,
  ].filter(v => !!v).join(', ');
}

export function formatRegisteredOfficeAddressMedium(address: SeekCompanyResultAddress) {
  return [
    address.premises,
    address.addressLine1,
    address.locality || address.region,
    address.postalCode
  ].filter(v => !!v).join(', ');
}


export function formatRegisteredOfficeAddressLong(address: SeekCompanyResultAddress) {
  return [
    address.careOf,
    address.poBox,
    address.premises,
    address.addressLine1,
    address.addressLine2,
    address.locality,
    address.region,
    address.country,
    address.postalCode
  ].filter(v => !!v).join(', ');
}

export function formatCompanyStatus(status: ContactAnalysisDataCompanyStatusEnum | SeekCompanyResultCompanyStatusEnum) {
  return companiesHouseEnumerations.company_status[status] || 'Unknown';
}

export function formatNatureOfBusiness(natureOfBusiness: ContactAnalysisCompanyNatureOfBusiness) {
  return `${natureOfBusiness.code} - ${natureOfBusiness.description}`;
}



export function formatOrganisationRole(organisationRole: XeroUserOrganisationRoleEnum) {
  switch (organisationRole) {
    case XeroUserOrganisationRoleEnum.Standard:
      return 'Standard';
    case XeroUserOrganisationRoleEnum.Financialadviser:
      return 'Advisor';
    default: 
      return 'Other';
  }
}


export function formatMeyseySubscriptionPlan(plan: SubscriptionPlanEnum) {
  switch (plan) {
    case SubscriptionPlanEnum.NUMBER_0:
      return '30 Day Free Trial';
    case SubscriptionPlanEnum.NUMBER_1:
      return 'Essential';
    case SubscriptionPlanEnum.NUMBER_2:
      return 'Standard';
    case SubscriptionPlanEnum.NUMBER_3:
      return 'Premium';
    default:
      return 'Unknown';
  }
}

export function formatXeroInvoiceStatus(status: InvoiceStatusEnum) {
  switch (status) {
    case InvoiceStatusEnum.Draft:
      return 'Draft';
    case InvoiceStatusEnum.Authorised:
      return 'Awaiting Payment';
    case InvoiceStatusEnum.Paid:
      return 'Paid';
    case InvoiceStatusEnum.Voided:
      return 'Voided';
    default:
      return capitalize(status);
  }
}

export function formatNounForInvoiceType(invoiceType: InvoiceTypeEnum, cap: boolean = true) {
  const noun = invoiceType === InvoiceTypeEnum.Accrec ? 'invoice' : 'bill';
  return (cap ? capitalize(noun) : noun);
}

export function formatConjunctiveList(list: string[]) {
  if (list.length === 1) return list[0];
  return `${list.slice(0, list.length - 1).join(', ')} and ${list[list.length - 1]}`;
}


export function formatInvoiceTitle(invoice: Invoice) {
  return `${formatNounForInvoiceType(invoice.type!)}${invoice.invoiceNumber ? ` ${invoice.invoiceNumber}` : ``} dated ${formatDateMedium(invoice.date!)}`;
}

export function formatNormalizedInvoiceTitle(invoice: NormalizedInvoice, cap = false) {
  return `${formatNounForNormalizedDocument(invoice, cap)}${invoice.invoiceNumber ? ` ${invoice.invoiceNumber}` : ``} dated ${formatDateMedium(invoice.date!)}`;
}

export function formatBankTransactionTitle(bankTransaction: BankTransaction) {
  return `Transaction: ${isSpend(bankTransaction) ? `Spend Money` : `Receive Money`}${bankTransaction.reference ? ` - ${bankTransaction.reference}` : ``} dated ${formatDateMedium(bankTransaction.date!)}`;
}

export function formatNormalizedBankTransactionTitle(bankTransaction: NormalizedBankTransaction) {
  return `Transaction: ${bankTransaction.type === NormalizedBankTransactionTypeEnum.Spend ? `Spend Money` : `Receive Money`}${bankTransaction.reference ? ` - ${bankTransaction.reference}` : ``} dated ${formatDateMedium(bankTransaction.date!)}`;
}


export function formatNounForNormalizedDocument(document: NormalizedInvoice | NormalizedBankTransaction, cap = false) {
  let noun: string;
  if (document.entity === 'NormalizedInvoice') {
    noun = document.type === NormalizedInvoiceTypeEnum.Payable ? 'bill' : 'invoice';
  }
  else {
    noun = document.type === NormalizedBankTransactionTypeEnum.Spend ? 'spend transaction' : 'receive transaction';
  }

  // turns out this capitalizes each word...
  return cap ? startCase(noun) : noun;
}
