

import dayjs from 'dayjs';

export interface ReportingPeriod {
  startDateTime: string;
  endDateTime: string;
}

export class ReportingPeriodCalculator {


  static getPriorMonths(referenceDate: string, numberOfPriorMonths: number): ReportingPeriod {
    
    return {
      startDateTime: dayjs(referenceDate).subtract(numberOfPriorMonths, 'month').startOf('month').format(),
      endDateTime: dayjs(referenceDate).subtract(1, 'month').endOf('month').format()
    };
    
  }


  static getCurrentMonthToDate(): ReportingPeriod {
    return {
      startDateTime: dayjs().startOf('month').format(),
      endDateTime: dayjs().endOf('day').format()
    };
  } 

  static getPriorFinancialYear(referenceDate: string, financialYearEndDay: number, financialYearEndMonth: number): ReportingPeriod {

    const referenceYearFinancialYearEndDate = dayjs(referenceDate).month(financialYearEndMonth - 1).date(financialYearEndDay).endOf('day').format();

    return {
      startDateTime: dayjs(referenceYearFinancialYearEndDate).isAfter(referenceDate) ? 
        dayjs(referenceYearFinancialYearEndDate).subtract(23, 'month').startOf('month').format() : 
        dayjs(referenceYearFinancialYearEndDate).subtract(11, 'month').startOf('month').format(),
      endDateTime: dayjs(referenceYearFinancialYearEndDate).isAfter(referenceDate) ? 
        dayjs(referenceYearFinancialYearEndDate).subtract(12, 'month').format() : 
        dayjs(referenceYearFinancialYearEndDate).format()
    };
  }

  static getCurrentFinancialYearToDate(financialYearEndDay: number, financialYearEndMonth: number): ReportingPeriod {

    const currentYearFinancialYearEndDate = dayjs().month(financialYearEndMonth - 1).date(financialYearEndDay).endOf('day').format();

    return {
      startDateTime: dayjs(currentYearFinancialYearEndDate).isAfter(dayjs().format()) ? 
        dayjs(currentYearFinancialYearEndDate).subtract(11, 'month').startOf('month').format() : 
        dayjs(currentYearFinancialYearEndDate).format(),
      endDateTime: dayjs().endOf('day').format()
    };
  }


}