import { ContactAnalysisConclusionTypeEnum, ContactAnalysisStateConclusionTypeEnum } from '../../api/generated';


export function getTitleForContactAnalysisConclusion(conclusionType: ContactAnalysisStateConclusionTypeEnum | ContactAnalysisConclusionTypeEnum, subjectName?: string) {
  switch (conclusionType) {
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasAccountsOverdue:
      return `${subjectName || 'This company'}'s accounts are overdue`;
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasConfirmationStatementOverdue:
      return `${subjectName || 'This company'}'s confirmation statement is overdue`;
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasArchivalStatus:
      return `${subjectName || 'This company'} is dissolved or otherwise archived`;
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasDistressedStatus:
      return `${subjectName || 'This company'} has a distressed status`;
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasOnlyOneOfficer:
      return `${subjectName || 'This company'} only has one active officer`;
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasOfficersAppointedInPast6Months:
      return `${subjectName || 'This company'} has had new officers appointed in past 6 months`;
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasOfficersResidingOverseas:
      return `${subjectName || 'This company'} has officers residing overseas`;
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasOfficersWithDisqualifications:
      return `${subjectName || 'This company'} has a disqualified officer`;
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasPoBoxAddress:
      return `The registered office for ${subjectName || 'this company'} is a PO Box`;
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasPreviousNamesInPast12Months:
      return `${subjectName || 'This company'} has changed name recently`;
    case ContactAnalysisStateConclusionTypeEnum.CompanyIsLessThan12MonthsOld:
      return `${subjectName || 'This company'} was formed less than 12 months ago`;
    case ContactAnalysisStateConclusionTypeEnum.CompanyIsLessThan24MonthsOld:
      return `${subjectName || 'This company'} was formed less than 24 months ago`;
    default:
      try {
        return conclusionType[0] + conclusionType.replace(/([A-Z]|[0-9]+)/g, ' $1').trim().toLowerCase().slice(1);
      } catch (e) {
        return 'Unknown';
      }
  }
}
