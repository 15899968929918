import { ContactAnalysisConclusionTypeEnum, ContactAnalysisStateConclusionTypeEnum } from '../../api/generated';


export function getSeverityForContactAnalysisConclusionType(conclusionType: ContactAnalysisStateConclusionTypeEnum | ContactAnalysisConclusionTypeEnum) {
  switch (conclusionType) {
    // case ContactAnalysisStateConclusionTypeEnum.CompanyHasAccountsOverdue:
    // case ContactAnalysisStateConclusionTypeEnum.CompanyHasConfirmationStatementOverdue:
    // case ContactAnalysisStateConclusionTypeEnum.CompanyHasOnlyOneOfficer:
    // case ContactAnalysisStateConclusionTypeEnum.CompanyHasOfficersResidingOverseas:
    // case ContactAnalysisStateConclusionTypeEnum.CompanyHasOfficersAppointedInPast6Months:
    // case ContactAnalysisStateConclusionTypeEnum.CompanyHasPoBoxAddress:
    //     return 'advisory';
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasArchivalStatus:
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasPreviousNamesInPast12Months:
    case ContactAnalysisStateConclusionTypeEnum.CompanyIsLessThan12MonthsOld:
      return 'important';
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasOfficersWithDisqualifications:
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasDistressedStatus:      
      return 'critical';      
    default:
      return 'advisory';
  }
}