import { Link } from 'react-router-dom';
import { InvoiceAnalysisConclusion, InvoiceAnalysisStateConclusion, InvoiceAnalysisStateConclusionTypeEnum, NormalizedInvoice } from '../../api/generated';
import { formatNormalizedInvoiceTitle } from '../formatters';
import { Anchor } from '@mantine/core';



export function getAlertHeadingForInvoiceAnalysisConclusion(normalizedInvoice: NormalizedInvoice, conclusion: InvoiceAnalysisConclusion | InvoiceAnalysisStateConclusion) {
  switch (conclusion.type) {    
    case InvoiceAnalysisStateConclusionTypeEnum.BankDetailsChanged:
      return <>
      The bank details on a <Anchor inherit component={Link} to={`/bills-and-invoices/invoices/${normalizedInvoice.extId}`}>{formatNormalizedInvoiceTitle(normalizedInvoice)}</Anchor> for your contact <Anchor inherit component={Link} to={`/suppliers-and-customers/${normalizedInvoice.extContactId}`}>{normalizedInvoice.contactName}</Anchor> 
      &nbsp;are different to those observed previously <Anchor inherit component={Link} to={`/bills-and-invoices/invoices/${(conclusion.data as any).otherInvoiceExtId}`}>here</Anchor>. 
      </>;
    default:
      try {
        return conclusion.type[0] + conclusion.type.replace(/([A-Z]|[0-9]+)/g, ' $1').trim().toLowerCase().slice(1);
      } catch (e) {
        return 'Unknown';
      }
  }
}
